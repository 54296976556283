import React from 'react';
import AiDevGurus from './AiDevGurus';

function App() {
  return (
    <AiDevGurus />
  );
}

export default App;

