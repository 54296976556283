import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './AiDevGurus.css';

const AiDevGurus = () => {
    return (
        <div className="background">
            <Container maxWidth="md" className="container">
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
                    <Typography variant="h2" component="h1" gutterBottom>
                        Welcome to AI Dev Gurus!
                    </Typography>
                    <Typography variant="body1" component="p" align="center">
                        Awesome things are in the works. If you are a company looking to unlock 
                        your team's potential, reduce development costs, and increase speed to market; 
                        you are in the right place.
                    </Typography>
                </Box>
            </Container>
        </div>
    );
};

export default AiDevGurus;

